.LoadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DisabledLoader {
  position: absolute;
  background: rgba(255,255,255,0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
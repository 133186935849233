@import '../../../styles/breakpoints';

.Logo {
  margin-bottom: 20px;
  margin-top: 40px;
  text-align: center;
  @include media-breakpoint-up(md) {
    margin-bottom: 30px;
  }
  img {
    height: auto;
    width: 100%;
    @include media-breakpoint-up(md) {
      height: 65px;
      width: auto;
    }
  }
}
$font-size-base: 1rem;
$line-height-base: 1.25;
$input-line-height: 1.5;
$input-border-radius: 0.15rem;
$btn-border-radius: 0.15rem;
$btn-font-weight: 600;
$input-btn-padding-y-lg: 0.25rem;
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4.5),
    7: ($spacer * 6),
    8: ($spacer * 7.5),
    9: ($spacer * 9)
  ),
  $spacers
);
$primary: #2A2B2A;
$secondary: #DFE4EA;
$info: #2182c5;
$danger: #A93524;

@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";
@import url("https://use.typekit.net/woq5blp.css");
// @import url('https://fonts.googleapis.com/css?family=Lato:400,400i&display=swap');

$form-control-shadow: rgba(38, 101, 197, 0.5);

.btn {
  line-height: 1.5;
  &-secondary,
  &-secondary:hover {
    color: $primary;
  }
  &-sm {
    line-height: 1.25;
  }
}

.border-app {
  border: 1px solid rgba(42, 42, 42, 0.2);
}

.input-group-append {
  .border-app.error {
    border: 1px solid $danger;
  }
}

label {
  font-weight: 600;
}

.btn-lg {
  font-size: 18px;
  height: 40px;
}

@include media-breakpoint-only(xs) {
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 95%;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 95%;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 95%;
  }
}

.btn-link {
  color: $info;
  &:hover {
    color: #2665c5;
  }
}

.text-ccc {
  color: #cccccc;
}

.form-control {
  color: #212529;
  &:focus {
    color: #212529;
    border-color: $form-control-shadow;
    box-shadow: 0 0 0 0.1rem $form-control-shadow;
  }
}

.underline {
  text-decoration: underline
}

.smaller {
  font-size: 60%;
}

.app-dropdown {
  button.dropdown-toggle {
    min-width: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
  }
  .dropdown-menu {
    min-width: 7rem;
    a.dropdown-item {
      padding: 0.375rem 0.75rem;
    }
  }
  &.is-invalid {
    & ~ .invalid-feedback {
      display: block;
    }
  }
}

.pointer {
  cursor: pointer;
}

a.dropdown-item {
  &.text-primary {
    color: $primary;
    &:hover {
      color: #fff !important
    }
  }
}

.has-error > .personaphile-react-select > div:first-child {
    border-color: #A93524;
}

.border-text-dark {
  color: $primary;
  border: 1px solid $primary;
}

.btn-outline-danger.text-primary:hover {
  color: #fff !important;
}
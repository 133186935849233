.Navbar {
  max-height: 50px;
  height: 100%;
  background: #283841;
}

.AccountLabelBack {
  cursor: pointer;
}

.DropdownMenuItem {
  color: #2b2b2b !important;
}

.DropdownMenuItem:hover, .DropdownMenuItemActive {
  background: #E1F3FC !important;
}

.UserNameMenu:hover {
  background: transparent;
  color: inherit
}
.ActivePersona {
  font-weight: 600;
  font-size: 18px;
  color: #ecf0f1 !important;
}
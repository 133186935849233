@import '../../../../styles/breakpoints';

.ModalContent {
  border-radius: 0;
  border: 0 none;
}

.ModalContent--full {
  height: 100%;
}

.ModalHeader {
  background: #e8e8e8;
  height: 40px;
  padding: 0 0 0 1.25rem;
  align-items: center;
  border-bottom: 1px solid rgba(42,42,42,0.2);
  border-radius: 0;
}

.ModalTitle {
  color: #2a2b2a;
  font-size: 16px;
  font-weight: 600;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.ModalClose {
  padding: 10px 15px;
  margin: 0;
  color: #2a2b2a;
  opacity: 0.8;
  cursor: pointer;
  height: 40px;
  position: relative;
}
.ModalClose:hover {
  background: transparent;
  color: #2a2b2a;
  opacity: 1;
}

.ModalClose:before {
  position: absolute;
  width: 1px;
  background: rgba(42,42,42,0.2);
  content: "";
  height: 70%;
  top: 6px;
  left: 0;
}

.ModalBody {
  padding: 1rem 1.25rem;
  @include media-breakpoint-down(sm) {
    overflow-y: auto;
    height: calc(100vh - 40px);
  }
  @include media-breakpoint-up(md) {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    &.OverflowVisible {
      overflow-y: visible;
    }
  }
  @include media-breakpoint-up(lg) {
    &::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }
    &::-webkit-scrollbar-track-piece {
      // background-color: #f1f1f1;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
      -webkit-border-radius: 10px;
      border-radius: 10px;
      &:window-inactive {
        visibility: hidden;
      }
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      // background: rgba(255,0,0,0.8); 
      -webkit-box-shadow: inset 0 0 6px rgba(123, 123, 123, 0.3);
      background-color: #c0c0c0;
      &:window-inactive {
        visibility: hidden;
      }
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(147, 147, 147);
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: rgb(123, 123, 123);
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
}
.ModalCloseNoHeader {
  padding: 20px 30px;
  color: #2a2b2a;
  opacity: 0.8;
  cursor: pointer;
  height: 60px;
  position: relative;
  text-align: right;
}

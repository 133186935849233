@import '../../../styles/breakpoints';

.AltLayout {
  box-shadow: inset 0px 15px 0px 0px #283841;
  // height: 100vh;
  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}

.Main {
  max-width: 300px;
  width: 100%;
  align-self: center;
  @include media-breakpoint-up(md) {
    min-width: 380px;
  }
}
.UserAvatar--dark {
  color: #FFF
}

.UserAvatar--light {
  color: #2C3E50
}

.UserAvatar--inner {
  /* font-size: 75%; */
}

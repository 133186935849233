.Modal {
  /* position: fixed;
  top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  min-width: 20rem;
  max-width: 35rem;
  width: 100%;
  outline: 0;
  opacity: 0;
  max-height: calc(100vh - 100px);
}

.lg {
  max-width: 60rem;
}

.sm {
  max-width: 20rem;
}

.full {
  max-width: none;
  top: 0;
  left: 0;
  transform: none;
  height: 100%;
  max-height: 100%;
}

.ModalIn  {
  /* transition: opacity 0.3s ease-in; */
  opacity: 1
}

.ModalOut {
  /* transition: opacity 0.3s ease-out; */
  opacity: 0
}

.Backdrop {
  background-color: #5e5e5e;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.BackdropIn  {
  /* transition: opacity 0.3s ease-in; */
  opacity: 0.5;
}

.BackdropOut  {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}